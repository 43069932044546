import Vue from "vue";
import Vuex from "vuex";

import authentication from "./modules/authentication";
import accommodations from "./modules/accommodations";
import users from "./modules/users";
import questionnaires from "./modules/questionnaires";
import questions from "./modules/questions";
import dashboards from "./modules/dashboards";
import contact from "./modules/contact";

Vue.use(Vuex);

let initialState = JSON.parse(
  JSON.stringify({
    authentication: authentication.state,
    accommodations: accommodations.state,
    users: users.state,
    questionnaires: questionnaires.state,
    questions: questions.state,
    dashboards: dashboards.state,
  })
);

export default new Vuex.Store({
  modules: {
    authentication,
    accommodations,
    users,
    questionnaires,
    questions,
    dashboards,
    contact,
  },
  mutations: {
    reset: (state) =>
      Object.keys(state).forEach((key) => {
        Object.assign(state[key], initialState[key]);
      }),
  },
});
