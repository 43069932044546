import $axios from "../../plugins/axios";

const state = {
  users: [],
};

const getters = {
  getUser: (state) => (userId) =>
    state.users.find((user) => user.id === userId),
  getUsers: (state) => state.users,
};

const mutations = {
  commitUsers: (state, users) => {
    state.users = users;
  },
};

const actions = {
  async fetchUsers(context) {
    if (context.state.users.length) return;
    let response = await $axios.get("/v1/users");
    context.commit("commitUsers", response.data);
  },
  createUser(context, newUser) {
    context.commit("commitUsers", [...context.state.users, newUser]);
  },
  updateUser(context, updatedUser) {
    let users = context.state.users.filter(
      (user) => user.id !== updatedUser.id
    );
    users.push(updatedUser);
    context.commit("commitUsers", users);
  },
  deleteUser(context, userId) {
    context.commit("commitUsers", [
      ...context.state.users.filter((user) => user.id !== userId),
    ]);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
