<template>
  <v-row style="height: 100vh">
    <div class="text-box">
      <h1>Diese Umfrage wurde bereits beantwortet.</h1>
      <h3>Vielen Dank, dass Sie an der Umfrage teilgenommen haben.</h3>
      <br />
      <h1>This survey has already been answered.</h1>
      <h3>Thank you for taking part in the survey.</h3>
    </div>
    <v-col cols="2" class="box-1"></v-col>
    <v-col cols="2" class="box-2"></v-col>
    <v-col cols="2" class="box-3"></v-col>
    <v-col cols="1" class="box-4"></v-col>
    <v-col cols="2" class="box-5"></v-col>
    <v-col cols="1" class="box-6"></v-col>
    <v-col cols="2" class="box-7"></v-col>
  </v-row>
</template>

<script>
export default {
  name: "Error404View",
  computed: {
    name() {
      return this.$store.getters["contact/getName"];
    },
    email() {
      return this.$store.getters["contact/getEmail"];
    },
  },
};
</script>

<style scoped>
.text-box {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  margin: 0 auto;
  text-align: center;
}

.box-1 {
  background-color: #4ca1c4;
}
.box-2 {
  background-color: #4c874c;
}
.box-3 {
  background-color: #edc84c;
}
.box-4 {
  background-color: #bb584c;
}
.box-5 {
  background-color: #c75e61;
}
.box-6 {
  background-color: #9c4c4c;
}
.box-7 {
  background-color: #f49c53;
}
</style>
