import $axios from "../../plugins/axios";
import jsonwebtoken from "jwt-decode";

const state = {
  isAuthenticated: false,
  accessToken: null,
  role: null,
  me: null,
  refreshTimeout: null,
  expireAt: null,
};

const getters = {
  getIsAuthenticated: (state) => state.isAuthenticated,
  getAccessToken: (state) => state.accessToken,
  getRole: (state) => state.role,
  getMe: (state) => state.me,
};

const mutations = {
  commitIsAuthenticated: (state, isAuthenticated) => {
    state.isAuthenticated = isAuthenticated;
  },
  commitAccessToken: (state, accessToken) => {
    state.accessToken = accessToken;
  },
  commitExpireAt: (state, expireAt) => {
    state.expireAt = expireAt;
  },
  commitRole: (state, role) => {
    state.role = role;
  },
  commitMe: (state, me) => {
    state.me = me;
  },
};

const actions = {
  fetchMe: async (context) => {
    const response = await $axios.get("/auth/me");
    context.commit("commitMe", response.data);
  },
  loginUserPassword: async (context, { username, password }) => {
    const params = new URLSearchParams();
    params.append("username", username);
    params.append("password", password);
    let response = await $axios.post("/auth/login", params);
    context.commit("commitAccessToken", response.data.access_token);
  },
  loginTwoFactor: async (context, { totp }) => {
    let response = await $axios.post("/auth/2fa-challenge", {
      verification_code: totp,
    });
    context.commit("commitAccessToken", response.data.access_token);
    const payload = jsonwebtoken(response.data.access_token);
    if (payload.token_type === "access") {
      context.commit("commitIsAuthenticated", true);
      context.commit("commitRole", payload.role);
      context.commit("commitExpireAt", payload.exp);
      clearTimeout(context.state.refreshTimeout);
      if (context.state.expireAt) {
        const timeoutMs = 4 * 60 * 1000; // 4 minutes
        context.state.refreshTimeout = setTimeout(
          () => context.dispatch("refresh"),
          timeoutMs
        );
      }
    }
  },
  refresh: async (context) => {
    let response = await $axios.post("/auth/refresh");
    context.commit("commitAccessToken", response.data.access_token);
    const payload = jsonwebtoken(response.data.access_token);
    if (payload.token_type === "access") {
      context.commit("commitIsAuthenticated", true);
      context.commit("commitRole", payload.role);
      context.commit("commitExpireAt", payload.exp);
      clearTimeout(context.state.refreshTimeout);
      if (context.state.expireAt) {
        const timeoutMs = 4 * 60 * 1000; // 4 minutes
        context.state.refreshTimeout = setTimeout(
          () => context.dispatch("refresh"),
          timeoutMs
        );
      }
    }
    await context.dispatch("fetchMe");
  },
  logout: async (context) => {
    clearTimeout(context.state.refreshTimeout);
    await $axios.post("/auth/logout");
    context.commit("reset", null, { root: true });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
