export const RoleEnum = {
  APPLICATION_ADMIN: "group:application_admin",
  ACCOMMODATION_ADMIN: "group:accommodation_admin",
  ACCOMMODATION_USER: "group:accommodation_user",
};

export const RoleTranslations = {
  "group:application_admin": "Systemadministrator*in",
  "group:accommodation_admin": "Unterkunftsadministator*in",
  "group:accommodation_user": "Unterkunftsnutzer*in",
};

export const AllUsers = Object.values(RoleEnum).map((role) => role);

export const intervals = [
  { text: "Monatlich", value: "MONTHLY" },
  { text: "Vierteljährlich", value: "QUARTERLY" },
  { text: "Jährlich", value: "YEARLY" },
  { text: "Kontinuierlich", value: "CONTINUOUS" },
];

export const questionWeightTypes = [
  {
    text: "Keine Gewichtung",
    value: null,
  },
  {
    text: "1",
    value: 1,
  },
  {
    text: "2",
    value: 2,
  },
  {
    text: "3",
    value: 3,
  },
];

export const questionTypes = [
  {
    text: "Fragetyp: Einfachauswahl",
    value: "LIST",
    hasSubQuestions: false,
  },
  {
    text: "Fragetyp: Mehrfachauswahl",
    value: "MULTIPLE_CHOICE",
    hasSubQuestions: false,
  },
  {
    text: "Fragetyp: Matrix-Einfachauswahl",
    value: "MATRIX",
    hasSubQuestions: true,
  },
  {
    text: "Fragetyp: Matrix-Mehrfachauswahl (x-Achse: Antwortoptionen; y-Achse: Fragen)",
    value: "MATRIX_MULTIPLE_CHOICE",
    hasSubQuestions: true,
  },
  {
    text: "Fragetyp: Matrix-Mehrfachauswahl (x-Achse: Fragen; y-Achse: Antwortoptionen)",
    value: "MATRIX_MULTIPLE_CHOICE_REVERSE",
    hasSubQuestions: true,
  },
  {
    text: "Fragetyp: Matrix-Zahleneingabe",
    value: "MATRIX_NUMBER",
    hasSubQuestions: true,
  },
  {
    text: "Fragetyp: Zahleneingabe",
    value: "NUMBER_INPUT",
    hasSubQuestions: false,
  },
  {
    text: "Fragetyp: Slider",
    value: "SLIDER",
    hasSubQuestions: true,
  },
  {
    text: "Fragetyp: Freitext-Feld",
    value: "TEXT_INPUT",
    hasSubQuestions: false,
  },
  {
    text: "Informationstext",
    value: "TEXT_MESSAGE",
    hasSubQuestions: false,
  },
];

export const visualizationTypes = [
  { text: "Bar Chart", value: "BAR_CHART" },
  { text: "Line Chart", value: "LINE_CHART" },
  { text: "Stacked Bar Chart", value: "STACKED_BAR_CHART" },
  { text: "Timeline Chart", value: "TIMELINE_CHART" },
  { text: "Tabelle", value: "TABLE_REGULAR" },
  { text: "Tabelle reversed", value: "TABLE_REVERSE" },
];

export const dashboardGroups = [
  {
    text: "Mindeststandards",
    value: "MINDESTSTANDARDS",
  },
  {
    text: "Grunddaten",
    value: "GRUNDDATEN",
  },
];
