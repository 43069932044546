import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import de from "vuetify/lib/locale/de";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#2e3440",
        secondary: "#4c566a",
        accent: "#88c0d0",
        error: "#bf616a",
        info: "#5e81ac",
        success: "#a3be8c",
        warning: "#ebcb8b",
        frost: "#8FBCBB",
      },
    },
  },
  lang: {
    locales: { de },
    current: "de",
  },
});
