import $axios from "../../plugins/axios";

const state = {
  accommodations: [],
};

const getters = {
  getAccommodation: (state) => (accommodationId) =>
    state.accommodations.find((e) => e.id === accommodationId),
  getAccommodations: (state) => state.accommodations,
};

const mutations = {
  commitAccommodations: (state, accommodations) => {
    state.accommodations = accommodations;
  },
};

const actions = {
  async fetchAccommodations(context) {
    if (context.state.accommodations.length) return;
    let response = await $axios.get("/v1/accommodations");
    context.commit("commitAccommodations", response.data);
  },
  createAccommodation(context, newAccommodation) {
    context.commit("commitAccommodations", [
      ...context.state.accommodations,
      newAccommodation,
    ]);
  },
  updateAccommodation(context, updatedAccommodation) {
    context.commit("commitAccommodations", [
      ...context.state.accommodations.filter(
        (accommodation) => accommodation.id !== updatedAccommodation.id
      ),
      updatedAccommodation,
    ]);
  },
  deleteAccommodation(context, accommodationId) {
    context.commit("commitAccommodations", [
      ...context.state.accommodations.filter(
        (accommodation) => accommodation.id !== accommodationId
      ),
    ]);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
