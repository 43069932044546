<template>
  <div>
    <div
      style="
        background-image: linear-gradient(to bottom right, #4c566a, #aaf2f7);
      "
    >
      <v-container
        class="d-flex flex-column align-center justify-center text-center"
        style="height: 60vh"
      >
        <h1 class="white--text text-uppercase font-weight-black display-3">
          DeZIM Gewaltschutzmonitor
        </h1>
        <h2 class="mt-5 white--text display-1">
          Monitoring des Gewaltschutzes in ihrer Unterkunft.
        </h2>
        <v-btn
          class="mt-10"
          color="white"
          outlined
          icon
          @click="$vuetify.goTo('#performance')"
        >
          <v-icon>mdi-arrow-down</v-icon>
        </v-btn>
      </v-container>
    </div>
    <v-container id="performance">
      <p class="my-6 text-justify">
        Der Gewaltschutzmonitor dient der kontinuierlichen Erfassung und
        Reflexion der Umsetzung des Gewaltschutzes in Ihrer Unterkunft. Durch
        regelmäßige Beantwortung von Fragen und die Eingabe von Daten zu
        zentralen Indikatoren des Gewaltschutzes können Sie Fortschritte und
        Verbesserungsmöglichkeiten erkennen. Auf dieser Seite finden Sie – über
        das Menü oben rechts – Fragebögen, zur Erfassung der Daten, sowie das
        Dashboard, über das wichtige Daten des Monitorings leicht zugänglich und
        visuell aufbereitet werden. Zudem können Sie alle in Ihrer Einrichtung
        erfassten Daten des Gewaltschutzes herunterladen und weiterverarbeiten.
        Durch Auswertungen gemeinsam mit Beteiligten und Betroffenen trägt das
        Monitoring zur Umsetzung und Verbesserung des Gewaltschutzes in Ihrer
        Einrichtung bei.
      </p>
      <v-row>
        <v-col cols="12" sm="8">
          <select-accommodation></select-accommodation>
        </v-col>
        <v-col cols="12" sm="4">
          <select-time-period></select-time-period>
        </v-col>
      </v-row>
      <radar-chart></radar-chart>
      <list-dashboards></list-dashboards>
    </v-container>
  </div>
</template>

<script>
import SelectAccommodation from "@/components/app/SelectAccommodation.vue";
import SelectTimePeriod from "@/components/app/SelectTimePeriod.vue";

import ListDashboards from "@/components/dashboards/ListDashboards.vue";
import RadarChart from "@/components/visualizations/RadarChart.vue";
export default {
  name: "HomeView",
  components: {
    SelectAccommodation,
    ListDashboards,
    RadarChart,
    SelectTimePeriod,
  },
};
</script>
