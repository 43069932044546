<template>
  <div v-if="accommodations" class="py-2">
    <v-select
      v-model="accommodationSelected"
      :items="accommodations"
      item-value="id"
      item-text="name"
      @change="changeRoute"
      outlined
      label="Unterkunft auswählen"
    ></v-select>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "SelectAccommodation",
  data: () => ({
    accommodationSelected: null,
  }),
  computed: {
    ...mapGetters("accommodations", { accommodations: "getAccommodations" }),
    accommodationId() {
      return this.$route.query?.accommodation_id;
    },
  },
  watch: {
    accommodationId() {
      this.accommodationSelected = this.$route.query?.accommodation_id;
    },
  },
  methods: {
    changeRoute(value) {
      this.$router.replace({
        name: this.$route.name,
        query: { ...this.$route.query, accommodation_id: value },
      });
    },
  },
  async created() {
    await this.$store.dispatch("accommodations/fetchAccommodations");
    if (!this.$route.query?.accommodation_id) {
      this.$router.replace({
        name: this.$route.name,
        query: {
          ...this.$route.query,
          accommodation_id: this.accommodations[0].id,
        },
      });
    }
    this.accommodationSelected = this.$route.query?.accommodation_id;
  },
};
</script>
