<template>
  <v-container>
    <v-row class="justify-center">
      <v-col cols="12" md="8" lg="6" xl="5">
        <h1 class="mt-12 mb-5">Passwort zurücksetzen</h1>
        <reset-password v-if="$route.params.token"></reset-password>
        <request-reset-email v-else></request-reset-email>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import RequestResetEmail from "../components/password-reset/RequestResetEmail.vue";
import ResetPassword from "../components/password-reset/ResetPassword.vue";

export default {
  name: "ViewPasswordReset",
  components: { RequestResetEmail, ResetPassword },
};
</script>
