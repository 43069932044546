<template>
  <v-container v-html="text"> </v-container>
</template>

<script>
import axios from "axios";

export default {
  name: "ImprintView",
  data: () => ({
    text: "",
  }),
  methods: {
    async fetchImprint() {
      let response = await axios.get("/imprint.html");
      this.text = response.data;
    },
  },
  async created() {
    await this.fetchImprint();
  },
};
</script>
