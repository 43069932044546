import $axios from "../../plugins/axios";

const state = {
  questionnaires: [],
};

const getters = {
  getQuestionnaires: (state) => state.questionnaires,
};

const mutations = {
  commitQuestionnaires: (state, questionnaires) => {
    state.questionnaires = questionnaires;
  },
};

const actions = {
  async fetchQuestionnaires(context) {
    if (context.state.questionnaires.length) return;
    let response = await $axios.get("/v1/questionnaires");
    context.commit("commitQuestionnaires", response.data);
  },
  createQuestionnaire(context, newQuestionnaire) {
    context.commit("commitQuestionnaires", [
      ...context.state.questionnaires,
      newQuestionnaire,
    ]);
  },
  updateQuestionnaire(context, updatedQuestionnaire) {
    context.commit("commitQuestionnaires", [
      ...context.state.questionnaires.filter(
        (questionnaire) => questionnaire.id !== updatedQuestionnaire.id
      ),
      updatedQuestionnaire,
    ]);
  },
  deleteQuestionnaire(context, questionnaireId) {
    context.commit("commitQuestionnaires", [
      ...context.state.questionnaires.filter(
        (questionnaire) => questionnaire.id !== questionnaireId
      ),
    ]);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
