var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-app-bar',{attrs:{"app":"","color":"primary lighten-4","dark":""}},[_c('v-row',[_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"4"}},[_c('router-link',{attrs:{"to":{
            name: 'Home',
            query: {
              ..._vm.$route.query,
            },
          }}},[_c('div',[_c('img',{staticStyle:{"height":"40px"},attrs:{"alt":"Logo","contain":"","src":"/logo.svg"}})])])],1),_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":"4"}},[_c('v-toolbar-title',[_c('strong',[_vm._v("Gewaltschutzmonitor")])])],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"4"}},[_c('v-btn',{attrs:{"icon":"","href":"https://www.gewaltschutzmonitor.de/","target":"__blank"}},[_c('v-icon',[_vm._v(" mdi-help-circle-outline ")])],1),(_vm.isAuthenticated && _vm.$route.name !== 'Preview')?_c('v-app-bar-nav-icon',{on:{"click":function($event){_vm.$refs.navigationDrawer.isVisible =
              !_vm.$refs.navigationDrawer.isVisible}}}):_vm._e()],1)],1)],1),(_vm.isAuthenticated && _vm.$route.name !== 'Preview')?_c('app-drawer',{ref:"navigationDrawer"}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }