import $axios from "../../plugins/axios";

const state = {
  dashboards: [],
};

const getters = {
  getDashboards: (state) => state.dashboards,
};

const mutations = {
  commitDashboards: (state, dashboards) => {
    state.dashboards = dashboards;
  },
};

const actions = {
  async fetchDashboards(context) {
    if (context.state.dashboards.length) return;
    let response = await $axios.get("/v1/dashboards");
    context.commit("commitDashboards", response.data);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
