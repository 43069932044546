<template>
  <div>
    <p>
      Bitte geben Sie Ihr neues Passwort ein. Das Passwort muss die folgenden
      Bedingungen erfüllen:
    </p>
    <v-expand-transition mode="out-in">
      <div v-if="success">
        <v-alert type="success">
          {{ success }}
        </v-alert>
        <v-btn :to="{ name: 'Login' }" color="primary">Zum Login</v-btn>
      </div>
    </v-expand-transition>
    <v-expand-transition mode="out-in">
      <v-alert v-if="error" type="error">
        {{ error }}
      </v-alert>
    </v-expand-transition>
    <v-expand-transition mode="out-in">
      <div v-if="!success">
        <password-rule
          :rule="checkPasswordLength"
          :value="password"
          text="Das Passwort muss mindestens <strong>12 Zeichen</strong> enthalten."
        ></password-rule>
        <password-rule
          :rule="checkPasswordLowerCaseLetters"
          :value="password"
          text="Das Passwort muss mindestens <strong>einen Kleinbuchstaben</strong> enthalten."
        ></password-rule>
        <password-rule
          :rule="checkPasswordUpperCaseLetters"
          :value="password"
          text="Das Passwort muss mindestens <strong>einen Großbuchstaben</strong> enthalten."
        ></password-rule>
        <password-rule
          :rule="checkPasswordDigits"
          :value="password"
          text="Das Passwort muss mindestens <strong>eine Zahl</strong> enthalten."
        ></password-rule>
        <password-rule
          :rule="checkPasswordSpecialCharacters"
          :value="password"
          text="Das Passwort muss mindestens <strong>ein Sonderzeichen</strong> enthalten."
        ></password-rule>
        <v-form
          v-model="isFormValid"
          class="mt-5"
          id="reset-password-form"
          ref="resetPassword"
          @submit.prevent="resetPassword"
        >
          <v-text-field
            v-model="password"
            :rules="[
              checkPasswordLength,
              checkPasswordLowerCaseLetters,
              checkPasswordUpperCaseLetters,
              checkPasswordDigits,
              checkPasswordSpecialCharacters,
            ]"
            :type="isPasswordVisible ? 'text' : 'password'"
            :append-icon="isPasswordVisible ? 'mdi-eye-off' : 'mdi-eye'"
            label="Neues Passwort"
            outlined
            @click:append="isPasswordVisible = !isPasswordVisible"
          ></v-text-field>
          <v-text-field
            v-model="confirmPassword"
            :rules="[checkPasswordsMatch]"
            :type="isConfirmPasswordVisible ? 'text' : 'password'"
            :append-icon="isConfirmPasswordVisible ? 'mdi-eye-off' : 'mdi-eye'"
            label="Neues Passwort wiederholen"
            outlined
            @click:append="isConfirmPasswordVisible = !isConfirmPasswordVisible"
          ></v-text-field>
          <v-btn
            :loading="isLoading"
            :disabled="!isFormValid"
            type="submit"
            form="reset-password-form"
            color="primary"
          >
            Passwort speichern
          </v-btn>
        </v-form>
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
import {
  checkPasswordLength,
  checkPasswordLowerCaseLetters,
  checkPasswordUpperCaseLetters,
  checkPasswordDigits,
  checkPasswordSpecialCharacters,
} from "../../utils/rules";
import PasswordRule from "./PasswordRule.vue";

export default {
  name: "ResetPassword",
  components: { PasswordRule },
  data: () => ({
    isFormValid: false,
    isLoading: false,
    error: false,
    success: false,
    password: "",
    isPasswordVisible: false,
    confirmPassword: "",
    isConfirmPasswordVisible: false,
    checkPasswordLength,
    checkPasswordLowerCaseLetters,
    checkPasswordUpperCaseLetters,
    checkPasswordDigits,
    checkPasswordSpecialCharacters,
  }),
  computed: {
    checkPasswordsMatch() {
      return (
        this.password === this.confirmPassword ||
        "Die Passwörter müssen übereinstimmen."
      );
    },
  },
  methods: {
    async resetPassword() {
      this.isLoading = true;
      this.error = false;
      this.success = false;
      try {
        await this.$axios.post("/auth/reset-password", {
          token: this.$route.params.token,
          password: this.password,
        });
        this.success = "Das Passwort wurde erfolgreich angepasst.";
      } catch (error) {
        if (error.response?.status === 400) {
          this.error =
            "Der Link ist nicht mehr gültig. Bitte fordern Sie einen neuen Link an.";
        } else {
          this.error =
            "Etwas ist schief gelaufen. Bitte versuchen Sie es erneut oder kontaktieren Sie einen Administrator.";
          console.error(error);
        }
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
