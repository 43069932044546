var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-navigation-drawer',{attrs:{"app":"","right":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('div',{staticClass:"pa-3 text-center"},[_c('v-btn',{attrs:{"color":"primary mb-2","block":""},on:{"click":_vm.logout}},[_vm._v(" Abmelden ")])],1)]},proxy:true}]),model:{value:(_vm.isVisible),callback:function ($$v) {_vm.isVisible=$$v},expression:"isVisible"}},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-h6"},[_vm._v(" Gewaltschutzmonitor ")]),(_vm.me)?_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.me.first_name)+" "+_vm._s(_vm.me.last_name)+" ")]):_vm._e(),(_vm.me)?_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.me.email)+" ")]):_vm._e()],1)],1),(!_vm.me)?_c('v-skeleton-loader',{staticClass:"mx-0",attrs:{"type":"list-item-two-line"}}):_vm._e(),(
      [_vm.RoleEnum.ACCOMMODATION_ADMIN, _vm.RoleEnum.ACCOMMODATION_USER].includes(
        _vm.role
      )
    )?_c('div',[_c('v-divider'),_c('span',{staticClass:"overline px-4"},[_vm._v("Dashboard")]),_c('v-list',[_c('v-list-item',{attrs:{"to":{
          name: 'Home',
          query: {
            ..._vm.$route.query,
          },
        },"exact-path":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Startseite")])],1)],1)],1),_c('list-dashboards-drawer')],1):_vm._e(),_c('v-divider'),(
      [_vm.RoleEnum.APPLICATION_ADMIN, _vm.RoleEnum.ACCOMMODATION_ADMIN].includes(
        _vm.role
      )
    )?_c('v-list',[_c('span',{staticClass:"overline px-4"},[_vm._v("Administration")]),_c('v-list-item',{attrs:{"to":{ name: 'Users' }}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Nutzer*innen")])],1)],1),(_vm.RoleEnum.APPLICATION_ADMIN == _vm.role)?_c('v-list-item',{attrs:{"to":{ name: 'Accommodations' }}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Unterkünfte")])],1)],1):_vm._e()],1):_vm._e(),_c('v-divider'),(_vm.role === _vm.RoleEnum.ACCOMMODATION_ADMIN && _vm.selectedAccommodationId)?_c('v-list',[_c('span',{staticClass:"overline px-4"},[_vm._v("Fragebögen")]),_c('v-list-item',{attrs:{"to":{
        name: 'QuestionnaireInvitations',
        query: {
          accommodation_id: _vm.selectedAccommodationId,
        },
      }}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Fragebögen Übersicht")])],1)],1),_c('v-list-item',{attrs:{"to":{
        name: 'Export',
        query: {
          accommodation_id: _vm.selectedAccommodationId,
        },
      }}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Export")])],1)],1)],1):_vm._e(),([_vm.RoleEnum.APPLICATION_ADMIN].includes(_vm.role))?_c('v-list',[_c('span',{staticClass:"overline px-4"},[_vm._v("Fragebögen")]),_c('v-list-item',{attrs:{"to":{
        name: 'Questionnaires',
      }}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Fragebögen bearbeiten")])],1)],1)],1):_vm._e(),([_vm.RoleEnum.APPLICATION_ADMIN].includes(_vm.role))?_c('v-list',[_c('span',{staticClass:"overline px-4"},[_vm._v("Dashboards")]),_c('v-list-item',{attrs:{"to":{
        name: 'Dashboards',
      }}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Dashboards bearbeiten")])],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }