<template>
  <div v-if="periods" class="py-2">
    <v-select
      v-model="periodSelected"
      :items="periods"
      item-text="text"
      @change="changeRoute"
      outlined
      return-object
      label="Zeitraum auswählen"
    ></v-select>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "SelectTimePeriod",
  data: () => ({
    periodSelected: null,

    periods: [],
  }),
  watch: {
    periodSelected: {
      deep: true,
      handler() {
        this.changeRoute();
      },
    },
  },
  methods: {
    changeRoute() {
      if (
        this.periodSelected &&
        (!this.$route.query.start_date ||
          !this.$route.query.end_date ||
          this.$route.query.start_date !== this.periodSelected.start ||
          this.$route.query.end_date !== this.periodSelected.end)
      ) {
        this.$router.replace({
          name: this.$route.name,
          query: {
            ...this.$route.query,
            start_date: this.periodSelected.start,
            end_date: this.periodSelected.end,
          },
        });
      }
    },
  },
  async created() {
    let startDate = this.$dayjs("2022-07-01");

    try {
      let response = await axios.get("/start-date.json");
      startDate = this.$dayjs(response.data.start_date);
    } catch {
      console.warn(
        `Start date could not be loaded. Using default: ${startDate.format(
          "YYYY-MM-DD"
        )}`
      );
    }

    while (startDate <= this.$dayjs().startOf("quarter")) {
      let endDate = startDate.add(3, "quarter").endOf("quarter");
      this.periods.push({
        text: `Q${startDate.format("Q YYYY")} - Q${endDate.format("Q YYYY")}`,
        start: startDate.format("YYYY-MM-DD"),
        end: endDate.format("YYYY-MM-DD"),
      });

      startDate = startDate.add(1, "quarter");
    }
    this.periods.reverse();

    if (!this.$route.query.start_date || !this.$route.query.end_date) {
      this.periodSelected = this.periods.slice(0)[0];
    } else {
      this.periodSelected = {
        text: `Q${this.$dayjs(this.$route.query.start_date).format(
          "Q YYYY"
        )} - Q${this.$dayjs(this.$route.query.end_date).format("Q YYYY")}`,
        start: this.$route.query.start_date,
        end: this.$route.query.end_date,
      };
    }
  },
};
</script>
