<template>
  <div>
    <v-row>
      <v-spacer></v-spacer>
      <v-btn icon @click="download()">
        <v-icon>mdi-download</v-icon>
      </v-btn>
    </v-row>
    <div>
      <v-alert v-if="error" type="error">{{ error }}</v-alert>
      <div class="chart" v-if="!isEmpty">
        <v-chart
          ref="chart"
          :option="chartOptions"
          class="chart"
          theme="theme"
          autoresize
        ></v-chart>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "RadarChart",
  props: {
    dashboardId: {
      required: false,
      type: String,
    },
  },
  data: () => ({
    error: false,
    isLoading: false,
    data: [],
  }),
  computed: {
    ...mapGetters("dashboards", {
      dashboards: "getDashboards",
    }),
    accommodation() {
      return this.$store.getters["accommodations/getAccommodation"](
        this.accommodationId
      );
    },
    isEmpty() {
      return !this.data?.series || this.data.series[0]?.data?.length === 0;
    },
    accommodationId() {
      return this.$route.query.accommodation_id;
    },
    startDate() {
      return this.$route.query.start_date;
    },
    endDate() {
      return this.$route.query.end_date;
    },
    chartOptions() {
      return {
        title: {
          text: this.dashboardId
            ? this.dashboards.find((d) => d.id === this.dashboardId).title
            : this.accommodation.name,
          subtext: this.dashboardId
            ? this.dashboards.find((d) => d.id === this.dashboardId).sub_title
            : "Gesamtauswertung",
          show: false,
        },
        grid: {
          containLabel: true,
        },
        tooltip: {
          trigger: "item",
        },
        legend: {
          data: this.data?.y_axis,
          itemGap: 20,
          top: 0,
        },
        radar: {
          indicator: this.data?.x_axis,
        },
        series: this.data?.series,
      };
    },
  },
  watch: {
    async accommodationId() {
      await this.fetchData();
    },
    async startDate() {
      await this.fetchData();
    },
    async endDate() {
      await this.fetchData();
    },
  },
  methods: {
    async fetchData() {
      if (!this.accommodationId || !this.startDate || !this.endDate) return;
      this.isLoading = true;
      this.error = false;
      try {
        const url = `/v1/visualizations/summary`;
        const response = await this.$axios.get(url, {
          params: {
            dashboard_id: this.dashboardId,
            accommodation_id: this.accommodationId,
            start_date: this.startDate,
            end_date: this.endDate,
          },
        });
        this.data = response.data;
      } catch (e) {
        console.error(e);
        this.error = "Daten konnten nicht geladen werden.";
      } finally {
        this.isLoading = false;
      }
    },
    download() {
      this.$refs.chart.setOption({
        title: {
          show: true,
        },
        legend: {
          data: this.data?.y_axis,
          itemGap: 20,
          bottom: 0,
        },
      });
      let imgUrl = this.$refs.chart.getDataURL({
        type: "png",
        pixelRatio: 5,
        backgroundColor: "#fff",
      });
      let tempA = document.createElement("a");
      tempA.download = "gewaltschutzmonitor" + ".png";
      tempA.href = imgUrl;
      document.body.appendChild(tempA);
      tempA.click();
      tempA.remove();
      this.$refs.chart.dispatchAction({
        type: "restore",
      });
    },
  },
  async created() {
    await this.fetchData();
  },
};
</script>

<style scoped>
.chart {
  min-height: 500px;
  width: 100%;
}
</style>
