<template>
  <div>
    <p>
      Bitte geben Sie Ihre Email-Addresse ein. Anschließend werden wir Ihnen
      eine Email mit dem Link, zum zurücksetzen Ihres Passworts, senden.
    </p>
    <v-expand-transition mode="out-in">
      <v-alert v-if="error" type="error">
        {{ error }}
      </v-alert>
    </v-expand-transition>
    <v-expand-transition mode="out-in">
      <v-alert v-if="success" type="success">
        {{ success }}
      </v-alert>
    </v-expand-transition>
    <v-expand-transition mode="out-in">
      <v-form
        v-if="!success"
        v-model="isFormValid"
        id="request-reset-form"
        @submit.prevent="requestResetEmail"
      >
        <v-text-field
          v-model="email"
          :rules="[checkEmptyEmail, checkEmail]"
          label="Email"
          outlined
        ></v-text-field>
        <v-btn
          :disabled="!isFormValid"
          type="submit"
          form="request-reset-form"
          color="primary"
        >
          Link anfordern
        </v-btn>
      </v-form>
    </v-expand-transition>
  </div>
</template>

<script>
import { checkEmptyEmail, checkEmail } from "../../utils/rules";
export default {
  name: "RequestResetEmail",
  data: () => ({
    isFormValid: false,
    isLoading: false,
    error: false,
    success: false,
    email: "",
    checkEmptyEmail,
    checkEmail,
  }),
  methods: {
    async requestResetEmail() {
      if (!this.isFormValid) return;
      this.error = false;
      this.success = false;
      this.isLoading = true;
      try {
        await this.$axios.post("/auth/forgot-password", {
          email: this.email,
        });
        this.success = "Eine Email wurde an Sie versandt.";
      } catch (error) {
        this.error =
          "Etwas ist schief gelaufen. Bitte versuchen Sie es erneut oder kontaktieren Sie einen Administrator.";
        console.error(error);
      }
    },
  },
};
</script>
