<template>
  <v-card>
    <v-card-title class="justify-center"> Gewaltschutzmonitor </v-card-title>
    <v-divider :class="{ 'mb-3': !error }"></v-divider>
    <v-expand-transition>
      <v-alert v-if="error" type="error" tile>{{ error }}</v-alert>
    </v-expand-transition>
    <v-card-text class="d-flex flex-column">
      <v-form v-model="isFormValid" @submit.prevent="signIn" id="basic-form">
        <v-text-field
          v-model="email"
          :rules="[checkEmptyEmail, checkEmail]"
          label="E-Mail"
          outlined
          autofocus
        ></v-text-field>
        <v-text-field
          v-model="password"
          :rules="[checkEmptyPassword]"
          :type="isPasswordVisible ? 'text' : 'password'"
          :append-icon="isPasswordVisible ? 'mdi-eye-off' : 'mdi-eye'"
          label="Passwort"
          outlined
          @click:append="isPasswordVisible = !isPasswordVisible"
        ></v-text-field>
      </v-form>
      <v-btn
        :disabled="!isFormValid"
        :loading="isLoading"
        type="submit"
        form="basic-form"
        color="primary"
        block
      >
        Anmelden
      </v-btn>
      <router-link :to="{ name: 'PasswordReset' }" class="mt-3 mx-auto">
        Sie haben Ihr Passwort vergessen?
      </router-link>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  checkEmptyPassword,
  checkEmptyEmail,
  checkEmail,
} from "../../utils/rules";
export default {
  name: "UserPasswordCard",
  data: () => ({
    isLoading: false,
    isFormValid: false,
    isPasswordVisible: false,
    email: "",
    password: "",
    error: undefined,
    checkEmptyEmail,
    checkEmail,
    checkEmptyPassword,
  }),
  methods: {
    async signIn() {
      if (!this.isFormValid) return;
      this.isLoading = true;
      this.error = undefined;
      try {
        await this.$store.dispatch("authentication/loginUserPassword", {
          username: this.email,
          password: this.password,
        });
        this.$emit("success");
      } catch (error) {
        this.error =
          "E-Mail oder Passwort falsch. Es ist weiterhin möglich, dass Ihr Account abgelaufen ist. In diesem Fall wenden Sie sich bitte an Ihre*n Adminstrator*in.";
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
