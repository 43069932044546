<template>
  <v-footer padless>
    <v-card flat tile class="text-center" color="grey lighten-4" width="100%">
      <v-card-text>
        <v-btn
          class="mx-1"
          href="https://www.gewaltschutzmonitor.de/"
          target="__blank"
          small
          text
        >
          Dokumentation
        </v-btn>
        <v-btn class="mx-1" small text :to="{ name: 'Imprint' }">
          Impressum
        </v-btn>
        <v-btn class="mx-1" small text :to="{ name: 'DataProtection' }">
          Datenschutz
        </v-btn>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-text>
        <v-container>
          <v-row class="justify-center">
            <v-col col="12" md="3">
              <p class="text-overline ma-0">Gefördert durch:</p>
              <v-img
                :src="require('@/assets/bmfsfj.svg')"
                class="mx-auto"
                height="150px"
                max-width="250px"
                contain
              ></v-img>
            </v-col>
            <v-col col="12" md="3">
              <p class="text-overline ma-0">Entwickelt durch:</p>
              <v-img
                :src="require('@/assets/dezim.svg')"
                class="mx-auto"
                height="150px"
                max-width="250px"
                contain
              ></v-img>
            </v-col>
            <v-col col="12" md="3" class="">
              <p class="text-overline ma-0">Umsetzung durch:</p>
              <v-img
                :src="require('@/assets/and-effect.svg')"
                class="mx-auto"
                height="150px"
                max-width="200px"
                contain
              ></v-img>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-text>
        <p class="mb-0">
          &copy; {{ $dayjs().year() }} — <strong>Gewaltschutzmonitor</strong>
        </p>
        <p class="mb-0 text-caption">Build Version: {{ version }}</p>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  name: "AppFooter",
  data: () => ({
    version: process.env.VUE_APP_BUILD_VERSION,
  }),
};
</script>
