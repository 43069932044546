import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import localizedFormat from "dayjs/plugin/localizedFormat";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import duration from "dayjs/plugin/duration";
import advancedFormat from "dayjs/plugin/advancedFormat";
import "dayjs/locale/de";

const $dayjs = dayjs;
$dayjs.extend(relativeTime);
$dayjs.extend(localizedFormat);
$dayjs.extend(quarterOfYear);
$dayjs.extend(duration);
$dayjs.extend(advancedFormat);
$dayjs.locale("de");

export default $dayjs;
