<template>
  <v-card>
    <v-card-title class="justify-center">
      Zwei-Faktor Authentifizierung
    </v-card-title>
    <v-divider :class="{ 'mb-3': !error }"></v-divider>
    <v-expand-transition>
      <v-alert v-if="error" type="error" tile>{{ error }}</v-alert>
    </v-expand-transition>
    <v-card-text>
      <p>
        Geben Sie den Verifizierungscode ein, den Sie per E-Mail erhalten haben.
        Bitte schauen Sie auch in Ihrem <strong>Spam-Ordner</strong> nach.
      </p>
      <v-form v-model="isFormValid" @submit.prevent="signIn" id="form-2fa">
        <v-otp-input
          v-model="totp"
          :rules="[checkTotp]"
          class="my-3"
          length="6"
          autofocus
        ></v-otp-input>
      </v-form>
      <v-btn
        :disabled="!isFormValid"
        :loading="isLoading"
        type="submit"
        form="form-2fa"
        block
        color="primary"
      >
        Anmelden
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
import { checkTotp } from "../../utils/rules";
import { RoleEnum } from "../../utils/types";
export default {
  name: "UserPasswordCard",
  data: () => ({
    isLoading: false,
    isFormValid: false,
    totp: "",
    error: undefined,
    checkTotp: checkTotp,
  }),
  methods: {
    async signIn() {
      if (!this.isFormValid) return;
      this.isLoading = true;
      this.error = undefined;
      try {
        await this.$store.dispatch("authentication/loginTwoFactor", {
          totp: this.totp,
        });
        await this.$store.dispatch("authentication/fetchMe");
        if (
          this.$store.getters["authentication/getRole"] ==
          RoleEnum.APPLICATION_ADMIN
        ) {
          this.$router.push({
            name: "Users",
          });
        } else {
          this.$router.push({
            name: "Home",
          });
        }
      } catch (error) {
        this.error = "Der Verifizierungscode ist ungültig oder abgelaufen.";
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
