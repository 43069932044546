import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import $axios from "./plugins/axios";
import $dayjs from "./plugins/dayjs";
import Echarts from "./plugins/echarts";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";
import QrcodeVue from "qrcode.vue";

Vue.config.productionTip = false;

Vue.prototype.$axios = $axios;
Vue.prototype.$dayjs = $dayjs;

Vue.component("qrcode-vue", QrcodeVue);
Vue.component("v-chart", Echarts);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
