// import "echarts";
import Echarts from "vue-echarts";
import { use, registerTheme } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { BarChart, LineChart, RadarChart, ScatterChart } from "echarts/charts";
import {
  GridComponent,
  TooltipComponent,
  LegendComponent,
  AxisPointerComponent,
  ToolboxComponent,
  TitleComponent,
  SingleAxisComponent,
} from "echarts/components";

import echartsTheme from "@/plugins/themes/echarts.json";

use([
  CanvasRenderer,
  LineChart,
  BarChart,
  RadarChart,
  ScatterChart,
  GridComponent,
  TooltipComponent,
  LegendComponent,
  AxisPointerComponent,
  ToolboxComponent,
  TitleComponent,
  SingleAxisComponent,
]);

registerTheme("theme", echartsTheme);

export default Echarts;
