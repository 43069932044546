import axios from "axios";
import store from "../store";
import router from "../router";

const $axios = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 10000,
  withCredentials: true,
});

$axios.interceptors.request.use(
  (config) => {
    const accessToken = store.getters["authentication/getAccessToken"];
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

$axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response?.status !== 401) {
      throw error;
    }
    if (error.config?.url === "/auth/refresh") {
      throw error;
    }
    try {
      await store.dispatch("authentication/refresh");
      return $axios.request(error.config);
    } catch {
      store.commit("reset");
      router.push({
        name: "Login",
        query: {
          session: 0,
        },
      });
    }
    return error;
  }
);

export default $axios;
