import axios from "axios";

const state = {
  contact: null,
};

const getters = {
  getName: (state) => state.contact?.name,
  getEmail: (state) => state.contact?.email,
};

const mutations = {
  commitContact: (state, contact) => {
    state.contact = contact;
  },
};

const actions = {
  async fetchContact(context) {
    if (context.state.contact) return;
    let response = await axios.get("/contact.json");
    context.commit("commitContact", response.data);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
