var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._l((_vm.dashboardGroups),function(group){return _c('div',{key:group},[_c('v-divider'),_c('span',{staticClass:"overline px-4"},[_vm._v(_vm._s(group))]),_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.dashboards
          .filter((d) => d.group == group)
          .sort((a, b) => a.position - b.position)),function(dashboard){return _c('v-list-item',{key:dashboard.id,attrs:{"to":{
          name: 'Dashboard',
          params: { dashboardId: dashboard.id },
          query: {
            ..._vm.$route.query,
            accommodation_id: _vm.accommodationId,
          },
        },"exact-path":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(dashboard.title)+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(dashboard.sub_title)+" ")])],1)],1)}),1)],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }