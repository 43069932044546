<template>
  <div>
    <v-app-bar app color="primary lighten-4" dark>
      <v-row>
        <v-col cols="4" class="d-flex align-center">
          <router-link
            :to="{
              name: 'Home',
              query: {
                ...$route.query,
              },
            }"
          >
            <div>
              <img alt="Logo" contain style="height: 40px" src="/logo.svg" />
            </div>
          </router-link>
        </v-col>
        <v-col cols="4" class="d-flex justify-center align-center">
          <v-toolbar-title>
            <strong>Gewaltschutzmonitor</strong>
          </v-toolbar-title>
        </v-col>
        <v-col cols="4" class="text-right">
          <v-btn
            icon
            href="https://www.gewaltschutzmonitor.de/"
            target="__blank"
          >
            <v-icon> mdi-help-circle-outline </v-icon>
          </v-btn>
          <v-app-bar-nav-icon
            v-if="isAuthenticated && $route.name !== 'Preview'"
            @click="
              $refs.navigationDrawer.isVisible =
                !$refs.navigationDrawer.isVisible
            "
          ></v-app-bar-nav-icon>
        </v-col>
      </v-row>
    </v-app-bar>
    <app-drawer
      v-if="isAuthenticated && $route.name !== 'Preview'"
      ref="navigationDrawer"
    ></app-drawer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import AppDrawer from "./AppDrawer.vue";

export default {
  name: "AppNavigation",
  components: { AppDrawer },
  computed: {
    ...mapGetters("authentication", {
      isAuthenticated: "getIsAuthenticated",
    }),
  },
};
</script>
