<template>
  <v-row class="my-4">
    <v-col
      v-for="dashboard in frontpageDashboards"
      :key="dashboard.id"
      cols="12"
      md="4"
    >
      <v-card outlined height="100%" class="d-flex flex-column">
        <v-card-title style="word-break: normal">
          {{ dashboard.title }}
        </v-card-title>
        <v-card-text class="flex-grow-1">
          {{ dashboard.description }}
        </v-card-text>

        <v-card-actions class="mb-2">
          <v-spacer></v-spacer>
          <v-btn
            :to="{
              name: 'Dashboard',
              params: { dashboardId: dashboard.id },
              query: { accommodation_id: $route.query.accommodation_id },
            }"
            exact-path
            color="primary"
          >
            {{ dashboard.sub_title }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ListDashboards",
  data: () => ({}),
  computed: {
    frontpageDashboards() {
      return this.dashboards
        .filter((d) => d.is_frontpage_item)
        .sort((a, b) => (a.position > b.position ? 1 : -1));
    },
    ...mapGetters("dashboards", {
      dashboards: "getDashboards",
    }),
  },
  async created() {
    await this.$store.dispatch("dashboards/fetchDashboards");
  },
};
</script>
