<template>
  <p class="mb-0">
    <v-icon v-if="rule(value) === true" color="success"> mdi-check </v-icon>
    <v-icon v-else color="error">mdi-close</v-icon>
    <span v-html="text" class="ml-1"></span>
  </p>
</template>

<script>
export default {
  name: "PasswordRule",
  props: ["rule", "value", "text"],
};
</script>
