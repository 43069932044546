import $axios from "../../plugins/axios";

const state = {
  questions: [],
};

const getters = {
  getQuestions: (state) => (questionnaireId) =>
    state.questions.filter((e) => e.questionnaire_id === questionnaireId),
  getQuestion: (state) => (questionId) =>
    state.questions.find((e) => e.id === questionId),
};

const mutations = {
  commitQuestions: (state, questions) => {
    state.questions = questions;
  },
};

const actions = {
  async fetchQuestions(context, questionnaireId) {
    let response = await $axios.get("/v1/questions", {
      params: {
        questionnaire_id: questionnaireId,
      },
    });
    context.commit("commitQuestions", [
      ...context.state.questions.filter(
        (e) => e.questionnaire_id !== questionnaireId
      ),
      ...response.data,
    ]);
  },
  createQuestion(context, newQuestion) {
    context.commit("commitQuestions", [
      ...context.state.questions,
      newQuestion,
    ]);
  },
  updateQuestion(context, updatedQuestion) {
    context.commit("commitQuestions", [
      ...context.state.questions.filter(
        (question) => question.id !== updatedQuestion.id
      ),
      updatedQuestion,
    ]);
  },
  deleteQuestion(context, questionId) {
    context.commit("commitQuestions", [
      ...context.state.questions.filter(
        (question) => question.id !== questionId
      ),
    ]);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
