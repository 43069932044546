export const isNotEmpty = (value) => {
  return !!value || "Bitte tragen Sie einen Wert ein.";
};

export const checkEmptyAccommodationName = (value) => {
  return (
    (!!value && value.length >= 3) ||
    "Bitte tragen Sie einen Namen für die Unterkunft ein."
  );
};

export const checkEmptyOrganization = (value) => {
  return (
    (!!value && value.length >= 3) || "Bitte tragen Sie eine Organisation ein."
  );
};

export const checkEmptyAddress = (value) => {
  return (
    (!!value && value.length >= 3) ||
    "Bitte tragen Sie eine Addresse für die Unterkunft ein."
  );
};

export const checkEmptyContact = (value) => {
  return (
    (!!value && value.length >= 3) ||
    "Bitte tragen Sie Kontaktdaten für die Unterkunft ein."
  );
};

export const checkEmptyName = (value) => {
  return (!!value && value.length >= 2) || "Bitte tragen Sie einen Namen ein.";
};

export const checkEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return (
    re.test(String(email).toLowerCase()) ||
    "Bitte geben Sie eine valide Email ein."
  );
};

export const checkConfirmDeletion = (value) => {
  return (
    value === "LÖSCHEN" ||
    "Bitte geben Sie das Wort 'LÖSCHEN' ein, um den Vorgang zu bestätigen."
  );
};

export const checkEmptyEmail = (value) => {
  return !!value || "Bitte geben Sie Ihre Email-Addresse ein.";
};

export const checkEmptyPassword = (value) => {
  return !!value || "Bitte geben Sie Ihr Passwort ein.";
};

export const checkTotp = (value) => {
  return (
    (!!value && value.length === 6) ||
    "Bitte geben Sie Ihren 6-stelligen Verifzierungscode ein."
  );
};

export const checkPasswordLength = (value) => {
  return (
    (!!value && value.length >= 12) ||
    "Das Passwort muss aus mindestens 12 Zeichen bestehen."
  );
};

export const checkPasswordLowerCaseLetters = (value) => {
  const re = /^(?=.*[a-zäöüß])(?=.{1,})/;
  return (
    re.test(value) ||
    "Das Passwort muss mindestens einen Kleinbuchstaben enthalten."
  );
};

export const checkPasswordUpperCaseLetters = (value) => {
  const re = /^(?=.*[A-ZÄÖÜ])(?=.{1,})/;
  return (
    re.test(value) ||
    "Das Passwort muss mindestens einen Großbuchstaben enthalten."
  );
};

export const checkPasswordDigits = (value) => {
  const re = /^(?=.*[0-9])(?=.{1,})/;
  return re.test(value) || "Das Passwort muss mindestens eine Zahl enthalten.";
};

export const checkPasswordSpecialCharacters = (value) => {
  const re = /^(?=.*[?!@#$%^&*])(?=.{1,})/;
  return (
    re.test(value) ||
    "Das Passwort muss mindestens ein Sonderzeichen enthalten."
  );
};

export const checkEmptySelect = (value) => {
  return value?.length > 0 || "Bitte wählen Sie mindestens ein Element aus.";
};

export const checkLength = (length, errorMessage) => {
  return (value) => value?.length <= length || errorMessage;
};
