<template>
  <v-app>
    <app-navigation></app-navigation>
    <v-main>
      <router-view v-if="!isLoading" :key="$route.path"></router-view>
    </v-main>
    <app-footer></app-footer>
  </v-app>
</template>

<script>
import AppFooter from "./components/layout/AppFooter.vue";
import AppNavigation from "./components/layout/AppNavigation.vue";

export default {
  name: "App",
  components: { AppNavigation, AppFooter },
  data: () => ({
    isLoading: false,
  }),
  created() {
    this.$store.dispatch("contact/fetchContact");
  },
};
</script>
