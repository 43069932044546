var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',[_vm._v(" Bitte geben Sie Ihr neues Passwort ein. Das Passwort muss die folgenden Bedingungen erfüllen: ")]),_c('v-expand-transition',{attrs:{"mode":"out-in"}},[(_vm.success)?_c('div',[_c('v-alert',{attrs:{"type":"success"}},[_vm._v(" "+_vm._s(_vm.success)+" ")]),_c('v-btn',{attrs:{"to":{ name: 'Login' },"color":"primary"}},[_vm._v("Zum Login")])],1):_vm._e()]),_c('v-expand-transition',{attrs:{"mode":"out-in"}},[(_vm.error)?_c('v-alert',{attrs:{"type":"error"}},[_vm._v(" "+_vm._s(_vm.error)+" ")]):_vm._e()],1),_c('v-expand-transition',{attrs:{"mode":"out-in"}},[(!_vm.success)?_c('div',[_c('password-rule',{attrs:{"rule":_vm.checkPasswordLength,"value":_vm.password,"text":"Das Passwort muss mindestens <strong>12 Zeichen</strong> enthalten."}}),_c('password-rule',{attrs:{"rule":_vm.checkPasswordLowerCaseLetters,"value":_vm.password,"text":"Das Passwort muss mindestens <strong>einen Kleinbuchstaben</strong> enthalten."}}),_c('password-rule',{attrs:{"rule":_vm.checkPasswordUpperCaseLetters,"value":_vm.password,"text":"Das Passwort muss mindestens <strong>einen Großbuchstaben</strong> enthalten."}}),_c('password-rule',{attrs:{"rule":_vm.checkPasswordDigits,"value":_vm.password,"text":"Das Passwort muss mindestens <strong>eine Zahl</strong> enthalten."}}),_c('password-rule',{attrs:{"rule":_vm.checkPasswordSpecialCharacters,"value":_vm.password,"text":"Das Passwort muss mindestens <strong>ein Sonderzeichen</strong> enthalten."}}),_c('v-form',{ref:"resetPassword",staticClass:"mt-5",attrs:{"id":"reset-password-form"},on:{"submit":function($event){$event.preventDefault();return _vm.resetPassword.apply(null, arguments)}},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-text-field',{attrs:{"rules":[
            _vm.checkPasswordLength,
            _vm.checkPasswordLowerCaseLetters,
            _vm.checkPasswordUpperCaseLetters,
            _vm.checkPasswordDigits,
            _vm.checkPasswordSpecialCharacters,
          ],"type":_vm.isPasswordVisible ? 'text' : 'password',"append-icon":_vm.isPasswordVisible ? 'mdi-eye-off' : 'mdi-eye',"label":"Neues Passwort","outlined":""},on:{"click:append":function($event){_vm.isPasswordVisible = !_vm.isPasswordVisible}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('v-text-field',{attrs:{"rules":[_vm.checkPasswordsMatch],"type":_vm.isConfirmPasswordVisible ? 'text' : 'password',"append-icon":_vm.isConfirmPasswordVisible ? 'mdi-eye-off' : 'mdi-eye',"label":"Neues Passwort wiederholen","outlined":""},on:{"click:append":function($event){_vm.isConfirmPasswordVisible = !_vm.isConfirmPasswordVisible}},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}}),_c('v-btn',{attrs:{"loading":_vm.isLoading,"disabled":!_vm.isFormValid,"type":"submit","form":"reset-password-form","color":"primary"}},[_vm._v(" Passwort speichern ")])],1)],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }