<template>
  <v-navigation-drawer v-model="isVisible" app right>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="text-h6">
          Gewaltschutzmonitor
        </v-list-item-title>
        <v-list-item-subtitle v-if="me">
          {{ me.first_name }} {{ me.last_name }}
        </v-list-item-subtitle>
        <v-list-item-subtitle v-if="me">
          {{ me.email }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-skeleton-loader
      v-if="!me"
      class="mx-0"
      type="list-item-two-line"
    ></v-skeleton-loader>

    <div
      v-if="
        [RoleEnum.ACCOMMODATION_ADMIN, RoleEnum.ACCOMMODATION_USER].includes(
          role
        )
      "
    >
      <v-divider></v-divider>
      <span class="overline px-4">Dashboard</span>
      <v-list>
        <v-list-item
          :to="{
            name: 'Home',
            query: {
              ...$route.query,
            },
          }"
          exact-path
        >
          <!-- accommodation_id: $route.query.accommodation_id, -->
          <v-list-item-content>
            <v-list-item-title>Startseite</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <list-dashboards-drawer></list-dashboards-drawer>
    </div>
    <v-divider></v-divider>

    <v-list
      v-if="
        [RoleEnum.APPLICATION_ADMIN, RoleEnum.ACCOMMODATION_ADMIN].includes(
          role
        )
      "
    >
      <span class="overline px-4">Administration</span>
      <v-list-item :to="{ name: 'Users' }">
        <v-list-item-content>
          <v-list-item-title>Nutzer*innen</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        v-if="RoleEnum.APPLICATION_ADMIN == role"
        :to="{ name: 'Accommodations' }"
      >
        <v-list-item-content>
          <v-list-item-title>Unterkünfte</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-divider></v-divider>
    <v-list
      v-if="role === RoleEnum.ACCOMMODATION_ADMIN && selectedAccommodationId"
    >
      <span class="overline px-4">Fragebögen</span>
      <v-list-item
        :to="{
          name: 'QuestionnaireInvitations',
          query: {
            accommodation_id: selectedAccommodationId,
          },
        }"
      >
        <v-list-item-content>
          <v-list-item-title>Fragebögen Übersicht</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        :to="{
          name: 'Export',
          query: {
            accommodation_id: selectedAccommodationId,
          },
        }"
      >
        <v-list-item-content>
          <v-list-item-title>Export</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-list v-if="[RoleEnum.APPLICATION_ADMIN].includes(role)">
      <span class="overline px-4">Fragebögen</span>
      <v-list-item
        :to="{
          name: 'Questionnaires',
        }"
      >
        <v-list-item-content>
          <v-list-item-title>Fragebögen bearbeiten</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-list v-if="[RoleEnum.APPLICATION_ADMIN].includes(role)">
      <span class="overline px-4">Dashboards</span>
      <v-list-item
        :to="{
          name: 'Dashboards',
        }"
      >
        <v-list-item-content>
          <v-list-item-title>Dashboards bearbeiten</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <template v-slot:append>
      <div class="pa-3 text-center">
        <v-btn color="primary mb-2" block @click="logout"> Abmelden </v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from "vuex";
import { RoleEnum } from "../../utils/types";
import ListDashboardsDrawer from "@/components/dashboards/ListDashboardsDrawer.vue";

export default {
  name: "AppDrawer",
  components: {
    ListDashboardsDrawer,
  },
  data: () => ({
    isVisible: false,
    RoleEnum: RoleEnum,
    currentAccommodationId: null,
  }),
  computed: {
    ...mapGetters("authentication", {
      me: "getMe",
      role: "getRole",
    }),
    ...mapGetters("accommodations", { accommodations: "getAccommodations" }),
    selectedAccommodationId() {
      return this.$route.query.accommodation_id || this.accommodations[0]?.id;
    },
  },
  methods: {
    async logout() {
      try {
        await this.$store.dispatch("authentication/logout");
        this.$router.push({ name: "Login" });
      } catch (error) {
        console.error(error);
      }
    },
  },
  async created() {
    await this.$store.dispatch("accommodations/fetchAccommodations");
  },
};
</script>
