<template>
  <div>
    <div v-for="group in dashboardGroups" :key="group">
      <v-divider></v-divider>
      <span class="overline px-4">{{ group }}</span>
      <v-list dense>
        <v-list-item
          v-for="dashboard in dashboards
            .filter((d) => d.group == group)
            .sort((a, b) => a.position - b.position)"
          :key="dashboard.id"
          :to="{
            name: 'Dashboard',
            params: { dashboardId: dashboard.id },
            query: {
              ...$route.query,
              accommodation_id: accommodationId,
            },
          }"
          exact-path
        >
          <v-list-item-content>
            <v-list-item-title>
              {{ dashboard.title }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ dashboard.sub_title }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ListDashboardsDrawer",
  data: () => ({}),
  computed: {
    ...mapGetters("accommodations", { accommodations: "getAccommodations" }),
    accommodationId() {
      return this.$route.query.accommodation_id || this.accommodations[0]?.id;
    },
    dashboardGroups() {
      var groups = this.dashboards
        .filter((d) => !!d.group)
        .sort((a, b) => (a.position > b.position ? 1 : -1))
        .map((d) => d.group);

      return [...new Set(groups)];
    },
    ...mapGetters("dashboards", {
      dashboards: "getDashboards",
    }),
  },
  async created() {
    await this.$store.dispatch("dashboards/fetchDashboards");
    await this.$store.dispatch("accommodations/fetchAccommodations");
  },
};
</script>
